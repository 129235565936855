import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { endpoints } from '../constants/endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getCustomers = () => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/customers`);
  getCustomer = (id: string) => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/customers/${id}`);
  activateUser = (id: string) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/activate/${id}`, {});
  desactivateUser = (id: string) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/desactivate/${id}`, {});
  addProfitPercentage(customerId: string, profitPercentageData: any): Observable<any> {
    return this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/${customerId}/profit-percentage`, profitPercentageData);
  }

 updateProfitPercentage(customerId: string, profitPercentageData: any): Observable<any> {
    return this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/${customerId}/profit-percentage`,
      profitPercentageData
    );
  }

  deleteProfitPercentage(customerId: string): Observable<any> {
    return this.http.delete(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/${customerId}/profit-percentage`
    );
  }

    FoundCustomerCountry(customerId: string): Observable<any> {
    return this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/${customerId}/found-customerCountry`);
  }
  verifyCustomer(id: string) {
    return this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/verify/${id}`, {});
  }

  rejectCustomer(id: string) {
    return this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/reject/${id}`, {});
  }

  addCustomerToCategory(customerData: any): Observable<any> {
    const url = `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/add-customer-tocategory`;
    return this.http.post<any>(url, customerData);
  }
  getCategoryPricing(customerId: string): Observable<any> {
    return this.http.get<any>(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/listCustomerCountryPricing/${customerId}`);
  }

  deletePercentage(customerCountryId: string, categoryId: string): Observable<any> {
    return this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/delete-percentage/${customerCountryId}/${categoryId}`);
  }

  updatePercentage(customerCountry: string, categoryId: string, data:any): Observable<any> {
    const url = `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.USERS}/update-percentage/${customerCountry}/${categoryId}`;
    return this.http.patch(url, data);
  }
}
