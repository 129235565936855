import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
   export class CategoriesService {
  findAllParents() {
    throw new Error('Method not implemented.');
  }
  remove(id: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  getParentCategories= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/parents`);

  getAllChilds= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/childs`);

  create= (data: any) => 
  this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}`,data);
  delete= (id:string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/${id}`);
  update = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/${id}`,data);
  
  getChildsCategories= (id:string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/childs/${id}`);
  findCategoryLanguage=(id:string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`);
createCategoryLanguage= (data: any) => 
this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/add-categorylanguages`,data);
deleteCategoryLanguage= (id:string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`);
updateCategoryLanguage = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`,data); 

getBrandMissingChildCategories = (brandId: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/missing/${brandId}`);
    findAllByBrand = (brandId: string) => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/by-brand?brand=${brandId}`);



}