export const endpoints = {
  ADMIN: {
    LOGIN: "auth/login/admin",
    BACKOFFICE: {
      USERS: "bo-admin-users",
      CATEGORY: "bo-admin-categories",
      ATTRIBUTE_KEY: "back-office-attribute-keys",
      LANGUAGE: "bo-admin-languages",
      UNIT: "bo-admin-units",
      COUNTRY: "bo-admin-countries",
      CURRENCIES: "bo-admin-currencies",
      DEPOSIT: "bo-admin-deposits",
      PRODUCT: "bo-admin-products",
      JETONPACK: "back-office-admin-jeton-packs",
      JETON_PACK_PURCHASE: "backoffice-jeton-pack-purchases",
      JETONPACKCOUNTRY: "back-office-jeton-pack-countries",
      BRAND: "back-office-admin-brands",
      FILE: "files/upload",
      MARKETPERIODS: "bo-admin-market-periods",
      ORDERS: "bo-admin-orders",
      PAYOUTS: "bo-admin-payouts",
      CONFIG: "bo-admin-config",
      VENDOR_INCOMES: "bo-admin-incomes",
    },
  },
  OFFICIAL: {
    BRAND: "official-brands",
  },
};
