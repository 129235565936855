import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { endpoints } from "../constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class PayoutsService {
  constructor(private http: HttpClient) {}

  getOne = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}/${id}`
    );

  reject = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}/${id}/reject`,
      data
    );

  process = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}/${id}/process`,
      {}
    );

  approve = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}/${id}/approve`,
      {}
    );

  getPayouts = (
    status?: string,
    from?: string,
    to?: string
  ): Observable<any> => {
    if (status && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}?status=${status}&from=${from}&to=${to}`
      );
    else if (status != null && (from == null || to == null))
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}?status=${status}`
      );
    else if (status == null && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PAYOUTS}?from=${from}&to=${to}`
      );
    else
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}`
      );
  };
}
