import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "features",
    loadChildren: () => import('../../features/features.module').then((m) => m.FeaturesModule),
  },
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
 
];
