import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { endpoints } from '../constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class JetonPacksService {

  constructor(private http: HttpClient) { }

  create= (data: any) => 
  this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACK}`,data);

  createPricing= (data: any) => 
  this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACKCOUNTRY}`,data);
  
  updatePricing= (id: string, data: any) => 
  this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACKCOUNTRY}/${id}`,data);
  
  delete= (id: string) => 
  this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACK}/${id}`);

  activate= (id: string) => 
  this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACK}/activate/${id}`, {});

  desactivate= (id: string) => 
  this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACK}/desactivate/${id}`, {});

  deletePricing= (id: string) => 
  this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACKCOUNTRY}/${id}`);


  findAll= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACK}`);

  findPricings= (id: string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETONPACKCOUNTRY}/${id}`);
  
}