import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public router: Router, private toastr: ToastrService,) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        console.error("error is intercept", error.status);
        if (error.status == 400)
        Swal.fire({
          icon: "warning",
          title:' Please verify your inputs',
          text: 'Please verify entered values'
        
        });
       
        else if (error.status == 500) 
        Swal.fire({
          icon: "error",
          title: 'Server Error Occured',
          text: 'Please Contact Technical Team',
        
        });
      
        else if (error.status == 401 || error.status == 403) { return throwError(null) }
        else 
        Swal.fire({
          icon: "warning",
          text:  error?.error?.message,
        });

      
        return throwError(error);


      
        
      })
    );
  }
}