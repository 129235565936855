
import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarketPeriodService {

  constructor(private http: HttpClient) { }

  findAll= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.MARKETPERIODS}`);
  
}
