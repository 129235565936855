import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { endpoints } from "../constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  constructor(private http: HttpClient) {}

  getConfig = () =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CONFIG}`
    );

  updateConfig = (data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CONFIG}`,
      data
    );

  getCurrenciesConfig = () =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CONFIG}/currencies`
    );

  updateCurrencyConfig = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CONFIG}/currencies/${id}`,
      data
    );
}
