import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
   export class CategoriesService {
  findAllParents() {
    throw new Error('Method not implemented.');
  }
  remove(id: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) { }

  getParentCategories= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/parents`);

  getAllChilds= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/childs`);

  create= (data: any) => 
  this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}`,data);
  delete= (id:string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/${id}`);
  update = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/${id}`,data);
  
  getChildsCategories= (id:string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/childs/${id}`);
  findCategoryLanguage=(id:string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`);
createCategoryLanguage= (data: any) => 
this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/add-categorylanguages`,data);
deleteCategoryLanguage= (id:string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`);
updateCategoryLanguage = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/categorylanguages/${id}`,data); 

getBrandMissingChildCategories = (brandId: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/missing/${brandId}`);
    findAllByBrand = (brandId: string) => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/by-brand?brand=${brandId}`);

    addPercentageToCategory1(categoryId: string, percentage: number): Observable<any> {
      const payload = { categoryId, percentage };
      return this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/add-percentage`, payload);
    }

    
    addPercentageToCategory(categoryId: string, data:any): Observable<any> {
      const url = `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/add-percentage/${categoryId}`;
      return this.http.patch(url, data);
    }



updatePercentage(categoryId: string, data:any): Observable<any> {
    const url = `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/update-percentage/${categoryId}`;
    return this.http.patch(url, data);
  }
  getCategoryDetails= (id:string) => 
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.CATEGORY}/${id}`);






}