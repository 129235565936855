import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/api/services/auth.service";
import { ILoginAdminResponse } from '../../core/api/interfaces/login-admin-response.interface';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;
  userData: ILoginAdminResponse;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private authService: AuthService) {

    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });

  }

  ngOnInit() { }

  login() {
    this.authService.loginAdmin(
      {
        email: this.loginForm.value['email'],
        password: this.loginForm.value['password']
      }
    ).subscribe(
      {
        next: (res: any) => {
          if (res.data) {
            this.userData = res.data;
            localStorage.setItem('admin', JSON.stringify(this.userData));
            localStorage.setItem("access_token", this.userData.accessToken);
            this.router.navigate(['/dashboard/default']);
          } else {
            localStorage.setItem('admin', null);
          }
        },
        error: (err) => {
          this.loginForm.reset()
        },
        complete: () => console.log('There are no more action happen.')
      }

    );
  }

  showPassword() {
    this.show = !this.show
  }
}
