import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttributeKeysService {

  constructor(private http: HttpClient) { }

  getAll = () =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}`);

  getAllByCategory = (categoryId: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/${categoryId}/attribute-keys`);

  getAllMissingByCategory = (categoryId: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/${categoryId}/missing-attribute-keys`);

  getOne = (id: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/${id}`);

  getAttributeChoice = (id: string) =>
    this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/choices/${id}`);

  create = (data: any) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}`, data);

  updateList = (data: any) =>
    this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/list`, data);


  update = (data: any) =>
    this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}`, data);

  addCategory = (data: any) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/add-category`, data);


  removeKey = (keyId: any) =>
    this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/${keyId}`);

  removeChoiceLanguage = (id: any) =>
    this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/delete-choice-language/${id}`);

  removeCategoryFromKey = (categoryId: any) =>
    this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/remove-category/${categoryId}`);

  createAttributeKeyLanguage = (data: any) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/add-language`, data);

  createChoice = (data: any) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/add-choice`, data);

  createChoiceLanguage = (data: any) =>
    this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/add-choice-language`, data);

  updateChoiceLanguage = (id: string, data: any) =>
    this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ATTRIBUTE_KEY}/update-choice-language/${id}`, data);
}