import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { endpoints } from "../constants/endpoints";
import { Observable } from "rxjs";
import { PickedOrderItemDto } from "../dto/add-picked-order-item.dto";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  getAllPurchases = (
    status?: string,
    from?: string,
    to?: string
  ): Observable<any> => {
    if (status && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases?status=${status}&from=${from}&to=${to}`
      );
    else if (status != null && (from == null || to == null))
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases?status=${status}`
      );
    else if (status == null && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases?from=${from}&to=${to}`
      );
    else
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases`
      );
  };

  getAllSales = (
    status?: string,
    from?: string,
    to?: string
  ): Observable<any> => {
    if (status && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales?status=${status}&from=${from}&to=${to}`
      );
    else if (status != null && (from == null || to == null))
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales?status=${status}`
      );
    else if (status == null && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales?from=${from}&to=${to}`
      );
    else
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales`
      );
  };

  addPickOrderItem = (id: string, pickedOrderItemDto: PickedOrderItemDto) => {
    return this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/${id}/pick-item`,
      pickedOrderItemDto
    );
  };

  inDelivery = (id: string) => {
    return this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}/in-delivery`,
      {}
    );
  };

  createShipment = (data: any) =>
    this.http.post(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/shipment`,
      data
    );

  confirmePurchase = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}/confirm`,
      data
    );

  confirmeSale = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales/${id}/confirm`,
      {}
    );

  cancelOrderItemPicking = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales/order-items/${id}/cancel-pick-item`,
      {}
    );
  cancelPurchase = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}/cancel`,
      {}
    );

  generateLabel = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/shipping-labels/${id}`
    );

  getSalesById = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/sales/${id}`
    );
  getPurchasesById = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}`
    );

  delivered = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}/delivered`,
      data
    );
  returned = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.ORDERS}/purchases/${id}/returned`
    );
}
