import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginAdminDto } from '../dto/login-admin.dto';
import { environment } from '../../../../environments/environment';
import { endpoints } from '../constants/endpoints';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {}

  loginAdmin = (loginDto: LoginAdminDto) => this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.LOGIN}`, loginDto);

}
