import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthService } from "./api/services/auth.service";
import { BrandsService } from "./api/services/brands.service";
import { CategoriesService } from "./api/services/categories.service";
import { FileService } from "./api/services/file.service";
import { LanguagesService } from "./api/services/language.service";
import { UsersService } from "./api/services/users.service";
import { UnitsService } from "./api/services/unit.service";
import { AttributeKeysService } from "./api/services/attribute-key.service";
import { ProductService } from "./api/services/products.service";
import { MarketPeriodService } from "./api/services/market-period.service";
import { JetonPacksService } from "./api/services/jeton-packs.service";
import { CountriesService } from "./api/services/countries.service";
import { DepositsService } from "./api/services/deposits.service";
import { CurrenciesService } from "./api/services/currencies.service"
import { JetonPackPurchasesService } from "./api/services/jeton-pack-purchases.service";
import { OrdersService } from "./api/services/orders.service";
import { PayoutsService } from "./api/services/payouts.service";
import { VendorIncomesService } from "./api/services/vendor-incomes.service"
import { ConfigService } from "./api/services/config.service"
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    UnitsService,
    CategoriesService,
    LanguagesService,
    AttributeKeysService,
    AuthService, UsersService, BrandsService,
    FileService,
    MarketPeriodService,
    JetonPacksService,
    ProductService,
    CountriesService,
    CurrenciesService,
    DepositsService,
    JetonPackPurchasesService,
    OrdersService,
    PayoutsService,
    VendorIncomesService,
    ConfigService
  ],

})
export class CoreModule { }