import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoints } from '../constants/endpoints';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  constructor(private http: HttpClient) { }
  //constructor(private http: HttpClient) { }
  getBrands = () => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}`);
  deleteBrands = (id: string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}/${id}`);
  /* updateBrands= (id:string) => this.http.put(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}/${id}`);*/
  updateBrands = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}/${id}`, data);

  creteBrand = (data: any) => this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}`, data);

  /*
    openSnackBar(message: string, action: string = 'ok') {
      this._snackBar.open(message, action, {
        duration: 1000,
        verticalPosition: 'top',
      });
    }*/

    deletebrandcategory =(brandId: string,CategoryId:string)=>this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}/${brandId}/${CategoryId}`);

addCategoryToBrand = (data: any) => {
  return this.http.post(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.BRAND}/add-category`,data )
}

getBrandsByCategoryId = (categoryId:string) => this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.OFFICIAL.BRAND}?category=${categoryId}`);


updateProductBrandById = (id: string, brand: string) => {
  return this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/update-brand`, {brand:brand});
}



}
