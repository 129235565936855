import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { endpoints } from "../constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class VendorIncomesService {
  constructor(private http: HttpClient) {}

  confirm = (id: string, data: any) =>
  this.http.patch(
    `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}/${id}/confirm`, data
  );

  cancel = (id: string) =>
  this.http.patch(
    `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}/${id}/cancel`, {}
  );

  getOne = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}/${id}`
    );

  getAll = (status?: string, from?: string, to?: string): Observable<any> => {
    if (status && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}?status=${status}&from=${from}&to=${to}`
      );
    else if (status != null && (from == null || to == null))
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}?status=${status}`
      );
    else if (status == null && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}?from=${from}&to=${to}`
      );
    else
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.VENDOR_INCOMES}`
      );
  };
}
