import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DepositsService {

  constructor(private http: HttpClient) { }

  findAll= (data) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.DEPOSIT}?type=${data.type}`);

  findOne= (id: string) => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.DEPOSIT}/${id}`);

  approve= (id: string, data: any) => 
  this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.DEPOSIT}/${id}/approve`, data);
  
  reject= (id: string, data: any) => 
  this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.DEPOSIT}/${id}/reject`, data);
  
}