import { HttpClient } from '@angular/common/http';

import { endpoints } from '../constants/endpoints';
import {environment} from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
   export class LanguagesService {

  constructor(private http: HttpClient) { }

  findAll= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.LANGUAGE}`);

  findAllForeign= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.LANGUAGE}/foreign`);
  
  createLanguage = (data: any) =>
    this.http.post(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.LANGUAGE}`,
      data
    );


    updateLanguages = (id: string, data: any) => this.http.patch(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.LANGUAGE}/${id}`, data);

    deleteLanguages = (id: string) => this.http.delete(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.LANGUAGE}/${id}`);


}
