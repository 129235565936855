import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { endpoints } from '../constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class JetonPackPurchasesService {

  constructor(private http: HttpClient) { }

  findAll= () => 
  this.http.get(`${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.JETON_PACK_PURCHASE}`);
  
}